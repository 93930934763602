.section-title {
  margin-top: 50px;
  margin-bottom: 50px;
  color: #051b35;
  font-size: 20px;
  font-family: 'Roboto Mono', sans-serif;
  font-weight: 400;
}
.section-title-contact{
  font-size: 18px;
  font-family: 'Roboto Mono', sans-serif;
  color: #051b35;
  font-weight: 400;
}
.section-title-login {
 margin-top: 50px;
 margin-bottom: 10px;
 color: #051b35;
 font-size: 30px;
 font-family: 'Roboto Mono', sans-serif;
 font-weight: 600;
}
.section-sub-title {
  color: #212121;
  font-size: 15px;
  font-family: 'Roboto Mono', sans-serif;
  font-weight: 300;
}
.product-name-on-card {
  color: #051b35;
  font-size: 16px;
  font-family: 'Roboto Mono', sans-serif;
  font-weight: 400;
}
.product-price-on-card {
  color: #E43023;
  font-size: 14px;
  font-family: 'Roboto Mono', sans-serif;
  font-weight: 600;
}
.category-name{
  color: #000000;
  font-size: 13px;
  font-family: 'Roboto Mono', sans-serif;
  font-weight: 600;
}
.text-link{
 color: inherit;
 text-decoration: inherit;
}
.breadbody{
 background-color: #e6e6e6;
 padding-top: 20px;
 padding-bottom: 7px;
 padding-left: 30px;
 font-weight: 600;
 border-radius: 10px;
}
.userprofile{
 width: 200px;
 height: 200px;
 margin-left: 80px;
}