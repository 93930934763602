@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Montserrat:ital,wght@0,400;0,600;0,800;1,700&family=Roboto+Mono:wght@300;400;700&family=Source+Sans+Pro:wght@400;600;700&display=swap');

@import 'bigSlider.css';
@import "button.css";
@import 'card.css';
@import 'footer.css';
@import 'megaMenu.css';
@import 'MegaMenuMobile.css';
@import 'megaMenuAll.css';
@import 'navMenuDesktop.css';
@import 'navMenuMobile.css';
@import 'typo.css';
@import 'responsiv.css';
@import 'userLogin.css';
@import 'common.css';
@import 'eventDetails.css';
@import 'notification.css';


body{
  background-color: #eee;
}